<template>
  <div class="teacher">
    <div class="card shadow-sm border-top-custom">
      <div class="card-header py-3">
        <div class="row align-items-center">
          <div class="col-md-8">
            <form v-on:submit.prevent="search">
              <div class="form-row">
                <div class="col-3 mr-2 mb-2">
                  <v-select
                    v-model="search_data.academic_year"
                    :options="years"
                    placeholder="Academic Year"
                  ></v-select>
                </div>

                <div class="col-4 mr-2 mb-2">
                  <v-select
                    label="name"
                    v-model="search_data.std_class_id"
                    :options="stdClass"
                    :reduce="(x) => x.id"
                    placeholder="Select class"
                  ></v-select>
                </div>

                <div class="col-3 mr-2 mb-2">
                  <v-select
                    label="name"
                    v-model="search_data.section_id"
                    :options="sections"
                    :reduce="(x) => x.id"
                    placeholder="Select Section"
                  ></v-select>
                </div>

                <div class="col-3 mr-2 mb-2" v-if="has_group">
                  <b-form-select
                    class="form-control form-control-sm"
                    v-model="search_data.group"
                    :options="$root.variable.study_group"
                    name="group"
                    size="sm"
                  >
                    <template>
                      <option value="">Select Group</option>
                    </template>
                  </b-form-select>
                </div>

                <div class="col-4 mr-2 mb-2">
                  <v-select
                    label="name"
                    v-model="search_data.exam_id"
                    :options="exams"
                    :reduce="(x) => x.id"
                    placeholder="Select Exam"
                  ></v-select>
                </div>

                <div class="form-group mb-0 col-2">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary rounded-0"
                  >
                    <span class="fa fa-search form-control-feedback"></span>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4 ml-auto pr-0 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <a
                class="btn btn-success btn-sm btn-excel"
                flat
                @click="downloadExcel"
              >
                <i class="far fa-file-excel"></i> Export
              </a>
              <button
                class="btn btn-primary btn-sm"
                flat
                @click="print('printArea', 'Result Sheet')"
              >
                <i class="fas fa-print"></i> Print
              </button>

              <button
                type="button"
                class="btn btn-sm btn-info rounded-0"
                @click="PrintResultSheet"
              >
                <span class="fa fa-file-pdf"></span>
                PDF Download
              </button>

              <!-- <button
                type="button"
                class="btn btn-sm btn-info rounded-0"
                @click="printFinalResultMarksheet"
              >
                <span class="fa fa-file-pdf"></span>
                Final Result
              </button> -->



            </div>
          </div>
        </div>
      </div>

      <div class="card-body" id="printArea">
        <div class="row">
          <div
            title="Student Information"
            class="col-12"
            style="text-align: center"
          >
            <h4 style="margin: 0">Cantonment English School & College</h4>
            <p style="margin: 0">Chattogram Cantonment</p>
            <h6 style="margin: 0">Result Sheet</h6>
            <h6 style="margin: 0">
              Academic Year
              {{ data.exam ? data.exam.academic_year : "" }}
            </h6>
          </div>
        </div>

        <div class="row" v-if="data.result_details.length > 0">
          <div class="col-12">
            <table width="100%">
              <tr>
                <td style="width: 60%; float: left">
                  <table
                    class="table table-info table-hover"
                    v-if="data.student"
                  >
                    <tbody>
                      <tr>
                        <th>Student ID</th>
                        <td>:</td>
                        <td>
                          {{ data.student.studentid }}
                        </td>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>:</td>
                        <td>{{ data.student.name }}</td>
                      </tr>
                      <tr v-if="data.stdclass">
                        <th>Class</th>
                        <td>:</td>
                        <td>
                          {{ data.stdclass ? data.stdclass.name : "" }}
                        </td>
                      </tr>
                      <tr v-if="data.section">
                        <th>Section</th>
                        <td>:</td>
                        <td>
                          {{ data.section ? data.section.name : "" }}
                        </td>
                      </tr>
                      <tr v-if="data.group">
                        <th>Group</th>
                        <td>:</td>
                        <td>{{ data.group }}</td>
                      </tr>
                      <tr>
                        <th>Roll</th>
                        <td>:</td>
                        <td>{{ data.roll }}</td>
                      </tr>
                      <tr>
                        <th>Class Rank</th>
                        <td>:</td>
                        <td>{{ data.class_rank }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="width: 40%; float: left">
                  <table class="table table-info table-hover" border="1">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Class Interval</th>
                        <th>Grade Point</th>
                        <th>Grade Letter</th>
                      </tr>
                    </thead>
                    <tbody style="text-align: center">
                      <slot v-for="(grade, index) in gradeSetup">
                        <tr :key="'w' + index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ grade.from_mark }}-{{ grade.to_mark }} %</td>
                          <td>
                            {{ grade.grade_point }}
                          </td>
                          <td>
                            {{ grade.grade_letter }}
                          </td>
                        </tr>
                      </slot>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>

            <div class="table-responsive">
              <table border="1" class="table table-info table-hover">
                <thead>
                  <tr>
                    <th rowspan="2" style="text-align: center">SN</th>
                    <th rowspan="2" style="text-align: center">Subject</th>
                    <th
                      :colspan="markLabel.length + 7"
                      style="text-align: center"
                    >
                      {{ data.exam ? data.exam.name : "" }}
                    </th>
                  </tr>
                  <tr>
                    <th style="text-align: center">Full Mark</th>
                    <th
                      style="text-align: center"
                      v-for="(label, n) in markLabel"
                      v-html="label.label"
                    ></th>
                    <th style="text-align: center">Highest Mark</th>
                    <th style="text-align: center">Total</th>
                    <th style="text-align: center">GP</th>
                    <th style="text-align: center">Grade</th>

                    <th style="text-align: center">Total Mark</th>
                    <th style="text-align: center">GPA</th>
                  </tr>
                </thead>

                <tbody>
                  <slot v-if="data.result_details.length > 0">
                    <slot
                      v-for="(studentSubject, index) in data.result_details"
                    >
                      <tr :key="Math.random()">
                        <td
                          :set="
                            (childSubject = allSubjectResults.find(
                              (e) =>
                                e.subject_id ==
                                studentSubject.result.exam_subject
                                  .merge_subject_id
                            ))
                          "
                          :rowspan="isMerge && childSubject ? 3 : 1"
                          style="text-align: center"
                        >
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ studentSubject.result.subject.name }}
                        </td>
                        <td>
                          {{ studentSubject.full_mark }}
                        </td>

                        <td
                          v-for="(label, n) in markLabel"
                          :key="Math.random() + 'k'"
                          style="text-align: center"
                        >
                          <!--{{ studentSubject.result.exam_subject[label.percentage]? `Percentage: ${studentSubject.result.exam_subject[label.percentage]}` : '' }}
                                                {{ studentSubject.result.exam_subject['total_'+label.converted_mark]? '' : 'N/A' }}
                                                {{ studentSubject[label.mark]? `Mark: ${studentSubject[label.mark]}` : '' }}
                                                {{ studentSubject[label.converted_mark]? `Converted Mark: ${studentSubject[label.converted_mark]}` : '' }}-->

                          {{
                            studentSubject.result.exam_subject[
                              "total_" + label.converted_mark
                            ]
                              ? ""
                              : "N/A"
                          }}
                          {{
                            studentSubject[label.converted_mark]
                              ? studentSubject[label.converted_mark]
                              : ""
                          }}
                        </td>

                        <td style="text-align: center">
                          {{
                            studentSubject.result
                              ? studentSubject.result.highest_mark
                              : "N/A"
                          }}
                        </td>
                        <td style="text-align: center">
                          {{
                            studentSubject.total_obtained_marks != null
                              ? studentSubject.total_obtained_marks
                              : "N/A"
                          }}
                        </td>

                        <td style="text-align: center">
                          {{ studentSubject.grade_point }}
                        </td>

                        <td style="text-align: center">
                          {{ studentSubject.grade_letter }}
                        </td>

                        <slot v-if="index == 0">
                          <td
                            style="text-align: center"
                            :rowspan="
                              allSubjectResults.length + mergeSubjectCount
                            "
                          >
                            {{ data.total_marks }}
                          </td>
                          <td
                            style="text-align: center"
                            :rowspan="
                              allSubjectResults.length + mergeSubjectCount
                            "
                          >
                            {{ data.grade_point }}
                          </td>
                        </slot>
                      </tr>

                      <slot v-if="isMerge && childSubject">
                        <tr>
                          <td>
                            {{ childSubject.result.subject.name }}
                          </td>
                          <td>
                            {{ childSubject.full_mark }}
                          </td>

                          <td
                            v-for="(label, n) in markLabel"
                            :key="Math.random() + 'k'"
                            style="text-align: center"
                          >
                            <!--{{ studentSubject.result.exam_subject[label.percentage]? `Percentage: ${studentSubject.result.exam_subject[label.percentage]}` : '' }}
                                                    {{ studentSubject.result.exam_subject['total_'+label.converted_mark]? '' : 'N/A' }}
                                                    {{ studentSubject[label.mark]? `Mark: ${studentSubject[label.mark]}` : '' }}
                                                    {{ studentSubject[label.converted_mark]? `Converted Mark: ${studentSubject[label.converted_mark]}` : '' }}-->

                            {{
                              childSubject.result.exam_subject[
                                "total_" + label.converted_mark
                              ]
                                ? ""
                                : "N/A"
                            }}
                            {{
                              childSubject[label.converted_mark]
                                ? childSubject[label.converted_mark]
                                : ""
                            }}
                          </td>

                          <td style="text-align: center">
                            {{
                              childSubject.result
                                ? childSubject.result.highest_mark
                                : "N/A"
                            }}
                          </td>
                          <td style="text-align: center">
                            {{
                              childSubject.total_obtained_marks != null
                                ? childSubject.total_obtained_marks
                                : "N/A"
                            }}
                          </td>

                          <td style="text-align: center">
                            {{ childSubject.grade_point }}
                          </td>

                          <td style="text-align: center">
                            {{ childSubject.grade_letter }}
                          </td>
                        </tr>

                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              (
                                (Number(studentSubject.full_mark) +
                                  Number(childSubject.full_mark)) /
                                2
                              ).toFixed(0)
                            }}
                          </td>

                          <td
                            v-for="(label, n) in markLabel"
                            :key="Math.random() + 'k'"
                            style="text-align: center"
                          >
                            <!--{{ studentSubject.result.exam_subject[label.percentage]? `Percentage: ${studentSubject.result.exam_subject[label.percentage]}` : '' }}
                                                    {{ studentSubject.result.exam_subject['total_'+label.converted_mark]? '' : 'N/A' }}
                                                    {{ studentSubject[label.mark]? `Mark: ${studentSubject[label.mark]}` : '' }}
                                                    {{ studentSubject[label.converted_mark]? `Converted Mark: ${studentSubject[label.converted_mark]}` : '' }}-->

                            {{
                              childSubject.result.exam_subject[
                                "total_" + label.converted_mark
                              ]
                                ? ""
                                : "N/A"
                            }}
                            {{
                              childSubject[label.converted_mark]
                                ? (
                                    (Number(
                                      childSubject[label.converted_mark]
                                    ) +
                                      Number(
                                        studentSubject[label.converted_mark]
                                      )) /
                                    2
                                  ).toFixed(0)
                                : ""
                            }}
                          </td>

                          <td style="text-align: center">
                            {{
                              childSubject.result
                                ? (
                                    (Number(childSubject.result.highest_mark) +
                                      Number(
                                        studentSubject.result.highest_mark
                                      )) /
                                    2
                                  ).toFixed(0)
                                : "N/A"
                            }}
                          </td>
                          <td style="text-align: center">
                            {{
                              childSubject.total_obtained_marks != null
                                ? (
                                    (Number(childSubject.total_obtained_marks) +
                                      Number(
                                        studentSubject.total_obtained_marks
                                      )) /
                                    2
                                  ).toFixed(0)
                                : "N/A"
                            }}
                          </td>

                          <td style="text-align: center">
                            {{
                              getMergeGradePoint(studentSubject, childSubject)
                            }}
                          </td>

                          <td style="text-align: center">
                            {{
                              getMergeGradeLetter(studentSubject, childSubject)
                            }}
                          </td>
                        </tr>
                      </slot>
                    </slot>
                  </slot>
                  <slot v-else>
                    <th
                      :colspan="markLabel.length + 8"
                      style="text-align: center"
                    >
                      No data found!
                    </th>
                  </slot>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-12 text-center">No data found.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Result",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2000 },
        (value, index) => 2002 + index
      );
    },
  },
  data() {
    return {
      has_group: false,
      isMerge: false,
      mergeSubjectCount: 0,
      search_data: {
        academic_year: new Date().getFullYear(),
        type: "student",
        std_class_id: "",
        section_id: "",
        group: "",
        studentid: "",
        exam_id: "",
      },

      stdClass: [],
      sections: [],
      exams: [],
      gradeSetup: [],
      data: {
        student: {},
        stdclass: {},
        section: {},
        exam: {},
        result_details: [],
      },
      allSubjectResults: [],
      markLabel: [],
      studentSection: "",
      studentClass: "",
      studentGroup: "",
      studentExamId: "",
    };
  },

  watch: {
    "search_data.academic_year": function (val, oldval) {
      this.search_data.exam_id = "";
      if (val) {
        this.getExam(val, this.search_data.std_class_id);
      }
    },
    "search_data.std_class_id": function (val, oldval) {
      this.search_data.group = "";
      this.search_data.exam_id = "";
      if (val) {
        this.getSections(val);
        this.getExam(this.search_data.academic_year, val);
        this.classHasGroup(val);
        this.getGradeSetup(val);
      }
    },
  },

  methods: {
    getData: function () {
      this.$root.loading = true;
      axios
        .get("student/result-sheet", { params: this.search_data })
        .then((res) => {
          this.allSubjectResults = res.data.result_details;
          this.data = res.data;
          this.markLabel = [];
          this.data.result_details.every((ele) => {
            if (ele.result.exam_subject.merge_subject_id) {
              this.isMerge = true;
              this.mergeSubjectCount++;
            }
            if (ele.result.exam_subject)
              this.makeMarkLabel(ele.result.exam_subject);
            return true;
          });

          this.data.result_details = this.data.result_details.filter((e) => {
            return (
              e.result.exam_subject.merge_subject_id ||
              this.isParentExist(
                e.subject_id,
                e.result.exam_subject.merge_subject_id
              )
            );
          });
        })
        .then(() => {
          this.$root.loading = false;
        });
    },

    isParentExist(subjectId, isMergeId) {
      if (isMergeId) return true;
      let parent = this.data.result_details.filter(
        (e) => e.result.exam_subject.merge_subject_id == subjectId
      );
      if (parent.length > 0) return false;
      return true;
    },

    makeMarkLabel(examSubject) {
      if (
        Number(examSubject.total_sub_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "sub_mark")
      ) {
        this.markLabel.push({
          label: "Sub Mark",
          mark: "per_sub_mark",
          converted_mark: "sub_mark",
          percentage: "sub_percentage_mark",
          pass_mark: "sub_pass_mark",
        });
      }

      if (
        Number(examSubject.total_obj_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "obj_mark")
      ) {
        this.markLabel.push({
          label: "Obj Mark",
          mark: "per_obj_mark",
          converted_mark: "obj_mark",
          percentage: "obj_percentage_mark",
          pass_mark: "obj_pass_mark",
        });
      }

      if (
        Number(examSubject.total_prac_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "prac_mark")
      ) {
        this.markLabel.push({
          label: "Prac Mark",
          mark: "per_prac_mark",
          converted_mark: "prac_mark",
          percentage: "prac_percentage_mark",
          pass_mark: "prac_pass_mark",
        });
      }

      if (
        Number(examSubject.total_ct_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "ct_mark")
      ) {
        this.markLabel.push({
          label: "CT Mark",
          mark: "per_ct_mark",
          converted_mark: "ct_mark",
          percentage: "ct_percentage_mark",
          pass_mark: "ct_pass_mark",
        });
      }

      if (
        Number(examSubject.total_cw_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "cw_mark")
      ) {
        this.markLabel.push({
          label: "CW Mark",
          mark: "per_cw_mark",
          converted_mark: "cw_mark",
          percentage: "cw_percentage_mark",
          pass_mark: "cw_pass_mark",
        });
      }

      if (
        Number(examSubject.total_assignment_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "assignment_mark")
      ) {
        this.markLabel.push({
          label: "Assignment",
          mark: "per_assignment_mark",
          converted_mark: "assignment_mark",
          percentage: "assignment_percentage_mark",
          pass_mark: "assignment_pass_mark",
        });
      }

      if (
        Number(examSubject.total_tpc_mark) > 0 &&
        !this.markLabel.find((x) => x.converted_mark === "tpc_mark")
      ) {
        this.markLabel.push({
          label: "Tree Plantation <br/> Cleanliness <br/> & <br/> Others",
          mark: "per_tpc_mark",
          converted_mark: "tpc_mark",
          percentage: "tpc_percentage_mark",
          pass_mark: "tpc_pass_mark",
        });
      }
    },

    getGradeSetup(stdClassId) {
      return axios.get("student/get-gradeSetup/" + stdClassId).then((res) => {
        this.gradeSetup = res.data.map((e) => {
          if (e.grade_point == 0) {
            e.from_grade_point = 0;
          } else {
            let preGradeSetup = res.data.find(
              (ele) => Number(ele.to_mark) == Number(e.from_mark) - 1
            );
            e.from_grade_point = preGradeSetup ? preGradeSetup.grade_point : 0;
          }
          return e;
        });
      });
    },

    getMergeGradeLetter(studentSubject, childSubject) {
      let gp = this.getMergeGradePoint(studentSubject, childSubject);
      let gradePoint = this.gradeSetup.find((e) => {
        return gp >= e.from_grade_point && gp <= e.grade_point;
      });
      return gradePoint ? gradePoint.grade_letter : "";
    },

    getMergeGradePoint(studentSubject, childSubject) {
      let sub_merge_pass_mark =
        studentSubject.result.exam_subject.sub_merge_pass_mark;
      let obj_merge_pass_mark =
        studentSubject.result.exam_subject.obj_merge_pass_mark;
      let prac_merge_pass_mark =
        studentSubject.result.exam_subject.prac_merge_pass_mark;

      let gp = 0;
      let total_obtained_marks = (
        (Number(childSubject.total_obtained_marks) +
          Number(studentSubject.total_obtained_marks)) /
        2
      ).toFixed(0);

      let gradePointLevel = this.gradeSetup.find((e) => {
        return (
          total_obtained_marks >= e.from_mark &&
          total_obtained_marks <= e.to_mark
        );
      });

      gp = gradePointLevel ? gradePointLevel.grade_point : "";

      if (
        studentSubject.per_sub_mark + childSubject.per_sub_mark <
        sub_merge_pass_mark
      ) {
        gp = 0;
      } else if (
        studentSubject.per_obj_mark + childSubject.per_obj_mark <
        obj_merge_pass_mark
      ) {
      } else if (
        studentSubject.per_prac_mark + childSubject.per_prac_mark <
        prac_merge_pass_mark
      ) {
        gp = 0;
      }

      return gp;
    },

    async printFinalResultMarksheet() {

      // Check final result is yet not published...
      if (this.data.result_details.length === 0 || !/Annual|Final/.test(this.data.exam.name)) {
          this.notification("Result is not published yet!", "error");
          return false;
      }

      this.$root.loading = true;

      let data = {
        grade_setup: this.gradeSetup,
        result: this.data,
        markLabel: this.markLabel,
        allSubjectResults: this.allSubjectResults,
        isMerge: this.isMerge,
        mergeSubjectCount: this.mergeSubjectCount,
      };

      axios({
        method: "post",
        url: "student/result-sheet/final/pdf",
        responseType: "arraybuffer",
        data: data,
      })
        .then((response) => {
          console.log(response);

          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          let fileName = this.search_data.academic_year + "_";
          fileName +=
            this.stdClass.find((e) => e.id == this.search_data.std_class_id)
              .name + "_";
          fileName +=
            this.sections.find((e) => e.id == this.search_data.section_id)
              .name + "_";
          fileName += this.search_data.group
            ? this.search_data.group + "_"
            : "";
          fileName +=
            this.exams.find((e) => e.id == this.search_data.exam_id).name + "_";
          fileName += this.search_data.studentid + "_";

          link.download = fileName + "result_Sheet.pdf";
          link.click();
        })
        .then(() => {
          this.$root.loading = false;
        });
    },

    PrintResultSheet() {
      if (this.data.result_details.length == 0) {
        this.notification("Result not published!", "error");
        return false;
      }

      this.$root.loading = true;
      let data = {
        grade_setup: this.gradeSetup,
        result: this.data,
        markLabel: this.markLabel,
        allSubjectResults: this.allSubjectResults,
        isMerge: this.isMerge,
        mergeSubjectCount: this.mergeSubjectCount,
      };
      axios({
        method: "post",
        url: "student/result-sheet/pdf",
        responseType: "arraybuffer",
        data: data,
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          let fileName = this.search_data.academic_year + "_";
          fileName +=
            this.stdClass.find((e) => e.id == this.search_data.std_class_id)
              .name + "_";
          fileName +=
            this.sections.find((e) => e.id == this.search_data.section_id)
              .name + "_";
          fileName += this.search_data.group
            ? this.search_data.group + "_"
            : "";
          fileName +=
            this.exams.find((e) => e.id == this.search_data.exam_id).name + "_";
          fileName += this.search_data.studentid + "_";

          link.download = fileName + "result_Sheet.pdf";
          link.click();
        })
        .then(() => {
          this.$root.loading = false;
        });
    },

    downloadExcel() {
      if (this.data.result_details.length == 0) {
        this.notification("Result not published!", "error");
        return false;
      }
      let table = document.getElementById("printArea");
      let html = table.outerHTML;
      let url = "data:application/vnd.ms-excel," + escape(html); // Set your html table into url
      $(".btn-excel").attr("href", url);

      let fileName = this.search_data.academic_year + "_";
      fileName +=
        this.stdClass.find((e) => e.id == this.search_data.std_class_id).name +
        "_";
      fileName +=
        this.sections.find((e) => e.id == this.search_data.section_id).name +
        "_";
      fileName += this.search_data.group ? this.search_data.group + "_" : "";
      fileName +=
        this.exams.find((e) => e.id == this.search_data.exam_id).name + "_";
      fileName += this.search_data.studentid + "_";

      $(".btn-excel").attr("download", fileName + "result_sheet.xls"); // Choose the file name
      return false;
    },

    getExam(academicYear, stdClassId) {
      if (!academicYear || !stdClassId) return;

      let data = {
        academic_year: academicYear,
        std_class_id: stdClassId,
      };
      axios
        .get("student/exam?allData=true", { params: data })
        .then((res) => (this.exams = res.data));
    },

    stdClassData() {
      return axios.get("student/stdclass?allData=true").then((res) => {
        this.stdClass = [];
        this.stdClass = res.data.filter(
          (e) =>
            Number(e.serial_no) <= Number(this.$parent.data.stdclass.serial_no)
        );
      });
    },

    getSections(stdClassId) {
      axios
        .get("student/section?allData=true&std_class_id=" + stdClassId)
        .then((res) => (this.sections = res.data));
    },

    search() {
      this.search_data.studentid = this.$parent.data.studentid;
      if (!this.search_data.academic_year) {
        this.notification("Please select academic year", "error");
      } else if (!this.search_data.std_class_id) {
        this.notification("Please select student class", "error");
      } else if (!this.search_data.section_id) {
        this.notification("Please select student section", "error");
      } else if (!this.search_data.exam_id) {
        this.notification("Please select a exam", "error");
      } else if (!this.search_data.studentid) {
        this.notification("Please Insert student ID", "error");
      } else {
        this.getData();
      }
    },
  },
  mounted() {
    this.stdClassData();
    setTimeout(() => (this.$root.loading = false), 1000);
  },
};
</script>
<style>
.iqbal-min-width {
  min-width: 105px !important;
}
</style>
